import {
API
} from 'aws-amplify'

async function createVerificationCode(lastName: string, emailAddress: string) {
	const res = API.post('SendVerificationCode', '/createVerificationCode', {
		body: {
			lastName,
			emailAddress
		}
	})
	return res
}

async function verifyCode(lastName: string, emailAddress: string, verificationCode: string, id: string, recaptchaToken: string) {
	const res = await API.post('SendVerificationCode', '/verifyCode', { 
		body: { 
			lastName, 
			emailAddress, 
			verificationCode,
			id,
			recaptchaToken
		}
	})
	return res
}

export { createVerificationCode as sendVerificationCode, verifyCode }
