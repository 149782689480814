import {
	MagnifyingGlassIcon
} from '@heroicons/vue//24/outline'
import SearchGolfer from './SearchGolferMain.vue'

const config = {
	mainRoute: {
		name: "SearchGolfer",
		path: "/",
		component: SearchGolfer 
	},
	module: "searchGolfer"
}

export { config }
