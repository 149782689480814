import {
	createRouter,
	createWebHistory
} from 'vue-router'
import {
	Route
} from '@/types' // must be imported since file contains an export statement

// Import all of the resource routes files
function loadRoutes() {
	const context = require.context('@/modules', true, /routes.ts$/i)
	return context.keys()
		.map(context) // import module
		.map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes()

resourceRoutes.forEach((moduleRoutes) => {
	moduleRoutes.forEach((route: Route) => {
		routes.push(route)
	})
})

// Create the router instance and pass the `routes` option
export const router = createRouter({
	history: createWebHistory(), // use history mode
	routes // short for `routes: routes`
})