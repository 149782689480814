/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import { router } from './router/index'
import './assets/css/index.css'

// import global components
import TwButton from '@/components/Buttons/TwButton.vue'
import TwInput from '@/components/InputGroups/TwInput.vue'
import TwLoading from '@/components/Loading/TwLoading.vue'
import DataDisplay from '@/components/DataDisplay/DataDisplay.vue'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// import icons from Tailwind's Hero Icons
// Solid icons
import {
	BarsArrowUpIcon,
	ExclamationCircleIcon,
	HomeIcon,
	MagnifyingGlassIcon,
	PlusSmallIcon,
	PhotoIcon
} from '@heroicons/vue/24/solid'

// Outline icons
import { 
	XCircleIcon,
	XMarkIcon
} from '@heroicons/vue/24/outline'

// Mini icons
import {
	ChevronDownIcon as ChevronDownIconMini,
	FunnelIcon as FunnelIconMini
} from '@heroicons/vue/20/solid'

Amplify.configure(awsconfig)

Amplify.configure({
	API: {
		endpoints: [
		  {
			name: "SendVerificationCode",
			endpoint: process.env.VUE_APP_HANDICAP_API_ENDPOINT,
		  }
		]
	}
})
const pinia = createPinia()
const app = createApp(App)

// Register icons globally
app.component('BarsArrowUpIcon', BarsArrowUpIcon)
app.component('PhotoIcon', PhotoIcon)
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('ExclamationCircleIcon', ExclamationCircleIcon)
app.component('FunnelIconMini', FunnelIconMini)
app.component('HomeIcon', HomeIcon)
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon)
app.component('PlusSmIcon', PlusSmallIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('XMarkIcon', XMarkIcon)
// In another component use, <HomeIcon/> directly in the template

// Register global components
app.component("tw-button", TwButton)
app.component("tw-input", TwInput)
app.component("tw-loading", TwLoading)
app.component("data-display", DataDisplay)

app.use(pinia)
app.use(router)

app.use(
	VueReCaptcha, 
	{ 
		siteKey: process.env.VUE_APP_RECAPTCHA_KEY, 
		loaderOptions: {
			autoHideBadge: true
		}
	}
)
app.mount('#app')
